<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="topicTitle" >
        <span slot="label" >
          <a-tooltip>
            {{$t('话题标题')}}<template slot="title">{{$t('话题标题')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.topicTitle" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('话题.话题标题')" />
      </a-form-model-item>

      <!--多语言集成-->
      <a-form-item v-if="form.localeList.length > 0" :label="this.$t('通用.文本.多语言配置')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">
        <a-table :data-source="form.localeList" :columns="localeColumns" rowKey="id" :pagination="false" bordered>
          <span slot="locale" slot-scope="text, record">
           <custom-dict-tag :options="customDict.LocaleEnum" :value="record.locale"/>
          </span>
          <div slot="name" slot-scope="text, record" >
            <a-input v-model="record.name" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('话题.话题标题')"   @blur.native.capture="validatorLocaleName"  />
          </div>
          <div slot="action" slot-scope="text, record">
            <a-button type="primary" @click="getTranslation(record)">
              {{$t('通用.按钮.翻译')}}
            </a-button>
          </div>
        </a-table>
        <span style="color: red;">{{ errorMessage }}</span>
      </a-form-item>
      <!--多语言集成-->



<!--      <a-form-model-item prop="imgUrl" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('话题图片')}}<template slot="title">{{$t('话题图片')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.imgUrl" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('话题.')" />-->
<!--      </a-form-model-item>-->




      <a-form-model-item :label="this.$t('短视频分类图片')" prop="imgUrl">
        <a-upload
            name="imgUrl"
            listType="picture-card"
            :multiple="false"
            :show-upload-list="false"
            :preview="handlePreview"
            :before-upload="beforeOssUpload"
            :customRequest="coverImgUpload">
          <img
              v-if="form.imgUrl"
              :src="form.imgUrl"
              alt="picture"
              style="height: 100px; width: 100px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
          </div>
        </a-upload>
        <span>推荐尺寸 100 x 100</span>
      </a-form-model-item>


<!--      <a-form-model-item prop="useNum" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('话题.使用量')}}<template slot="title">{{$t('话题.使用量')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.useNum"  :placeholder="$t('通用.输入.请输入')+$t('话题.使用量')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="remark" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('话题.备注')}}<template slot="title">{{$t('话题.备注')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.remark" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('话题.备注')" />-->
<!--      </a-form-model-item>-->
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTopic, addTopic, updateTopic } from '@/api/video/topic'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
//多语言集成
import {translation} from "@/api/tool/common";
//多语言集成

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        topicTitle: null,

        imgUrl: null,

        createTime: null,

        useNum: null,

        remark: null,
        // 多语言集成
        localeList: [],
        // 多语言集成
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      },
      //多语言集成
      errorMessage:undefined,
      localeColumns: [
        {
          title: this.$t('通用.文本.语言'),
          dataIndex: 'locale',
          key: 'locale',
          scopedSlots: { customRender: 'locale' }
        }, {
          title: this.$t('主营行业.分类名称'),
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          key: 'name'
        }, {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'action',
          key: 'action',
          slots: { title: 'addbtn', customRender: 'brandName' },
          scopedSlots: { customRender: 'action' }
        }
      ],
      //多语言集成
    }
  },
  filters: {
  },
  created () {
  },
  //多语言集成
  computed: {
    ...mapGetters(['customDict'])
},
//多语言集成
  watch: {
  },
  mounted () {
  },
  methods: {


    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'topic'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'imgUrl', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束



    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        topicTitle: null,
        imgUrl: null,
        createTime: null,
        useNum: null,
        remark: null,
        //多语言集成
        localeList:[]
        //多语言集成
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加');
      //多语言集成
      this.initLocale();
      //多语言集成
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTopic({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateTopic(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addTopic(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    //多语言集成
    initLocale() {
      //获取全局配置的语言
      const sysLangList = globalThis.$openLangList;
      //初始化多语言
      let localeList = [];
      let i = 1;
      this.customDict.LocaleEnum.forEach(e => {
        if(sysLangList.includes(e.type)){
        localeList.push({"id": i, "locale": e.type, 'name': ''})
        i++;
      }
    })
      this.form.localeList = localeList;
    },

    validatorLocaleName() {
      let errorMessage = "";
      var localeList = this.form.localeList;
      let localeEnum = this.customDict.LocaleEnum;
      localeList.forEach(e=>{
        if (!e.name) {
        let localeName = localeEnum.find(locale=>locale.type===e.locale).name;
        errorMessage += localeName + this.$t('通用.文本.不能为空')+';';
      }
    })
      if (errorMessage) {
        this.errorMessage = errorMessage;
        return false;
      }
      this.errorMessage = "";
      return true;
    },
    getTranslation(record) {
      if (!this.form.topicTitle) {
        return
      }
      let data = {"text": this.form.topicTitle, "locale": record.locale};
      translation(data).then(response => {
        record.name = response.data
    });

    },
    limitSort(record) {
      // console.log(i);
      let val=record.sort.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      if (val > 21474836) {
        val = 21474836
      }
      record.sort = val
    },
    //多语言集成
  }
}
</script>
